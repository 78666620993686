import 'babel-polyfill';
import React from "react";
import ReactDOM from "react-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./index.css";
import App from "./App";
import registerServiceWorker from './registerServiceWorker';
import Amplify from "aws-amplify";
import config from "./config";

Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: "ICApi",
          endpoint: config.apiGateway.URL + "/api/",
          region: config.apiGateway.REGION
        },
        {
          name: "ICApiC1",
          endpoint: config.apiGateway.URL + "/c1/",
          region: config.apiGateway.REGION
        }
      ]
    },
    Storage: {
      AWSS3: {
        bucket: 'upload.island-codes.com',
        region: 'eu-central-1',
        identityPoolId: config.cognito.IDENTITY_POOL_ID
    }
    }
  });
  

ReactDOM.render( <App /> , document.getElementById('root'));

registerServiceWorker();