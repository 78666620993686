import React, { Fragment } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { MDBInput, MDBIcon, MDBSideNavItem, MDBSideNavCat, MDBSideNavNav, MDBSideNav, MDBContainer, NavLink, Navbar, NavItem, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBNavItem, MDBDropdownToggle, Collapse, NavbarToggler, NavbarNav } from "mdbreact";
import "./Navigation.css";


class DoubleNavigationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleStateA: false,
      breakWidth: 1300,
      windowWidth: 0
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth
    });

  handleToggleClickA = () => {
    this.setState({
      toggleStateA: !this.state.toggleStateA
    });
  };

  render() {

    const mainStyle = {
      margin: "0 6%",
      paddingTop: "5.5rem",
      paddingLeft:
        this.state.windowWidth > this.state.breakWidth ? "240px" : "0"
    };
    const styleNav = {backgroundColor: '#2a2a2a', fontSize: "1.7rem"};
    const navItemStyle = {maxWidth: "700px"};
    const navIconStyle = {maxWidth: "250px", float: "right", flexDirection: "row"};
    return (
      
      
        <div className="fixed-sn rgba-stylish-slight">
        <Router>
        <Fragment>
        <Navbar style={styleNav} dark expand="md" scrolling fixed="top">
                    
                    <NavbarToggler onClick={this.onClick} />
                    <Collapse isOpen = { this.state.collapse } navbar className=" text-right">
                      <NavbarNav className="mx-auto" style={navItemStyle}>
                       
                      </NavbarNav>
                      <NavbarNav style={navIconStyle}>
                        <NavItem>
                        <NavLink to="//discord.gg/AAMD8tw" onClick={() => { window.open("https://discord.gg/AAMD8tw", "_blank")}}><MDBIcon fab icon="discord" /></NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink to="//www.instagram.com/fortnitecreativeisland_codes/" onClick={() => { window.open("https://www.instagram.com/fortnitecreativeisland_codes/", "_blank")}}><MDBIcon fab icon="instagram" /></NavLink>
                        </NavItem>
                        <NavItem>
                        <NavLink to="//www.youtube.com/channel/UCFdH7BM7PzYaxhS2TacIZ-g" onClick={() => { window.open("https://www.youtube.com/channel/UCFdH7BM7PzYaxhS2TacIZ-g", "_blank")}}><MDBIcon fab icon="youtube" /></NavLink>
                        </NavItem>
                        


                        {this.props.props.isAuthenticated
                        ? <MDBNavItem>
                          <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                              <MDBIcon icon="user" />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="dropdown-warning" right>
                              <MDBDropdownItem  onClick={this.props.props.logout}>Logout</MDBDropdownItem>
                              {this.props.props.userGroup === "admin" && <MDBDropdownItem><Link to="/admin/thesource/" onClick={this.onClick}>Admin Panel</Link></MDBDropdownItem>}
                              <MDBDropdownItem><Link to="/admin" onClick={this.onClick}>Creator Account</Link></MDBDropdownItem>
                              <MDBDropdownItem href="#!" disabled>Go Premium</MDBDropdownItem>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBNavItem>
                        : <MDBNavItem>
                          <MDBDropdown>
                            <MDBDropdownToggle nav caret>
                              <MDBIcon className="yellow-text" icon="user" />
                            </MDBDropdownToggle>
                            <MDBDropdownMenu className="dropdown-warning" right>
                              <Link to="/register" className="p-0" onClick={this.onClick}><MDBDropdownItem className="text-uppercase">Register</MDBDropdownItem></Link>
                              <Link to="/login" className="p-0" onClick={this.onClick}><MDBDropdownItem className="text-uppercase">Login</MDBDropdownItem></Link>
                            </MDBDropdownMenu>
                          </MDBDropdown>
                        </MDBNavItem>
                        
                        }
                      </NavbarNav>
                    </Collapse>
                  </Navbar>
          <MDBSideNav
            logo="https://s3.eu-central-1.amazonaws.com/cdn.eu.com/c/islandcodes/img/logo.png"
            triggerOpening={this.state.toggleStateA}
            breakWidth={this.state.breakWidth}
            className="elegant-color"
          >
            <li>
              <ul className="social">
                <li>
                  <a href="#!">
                    <MDBIcon fab icon="facebook-f" />
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <MDBIcon fab icon="pinterest" />
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <MDBIcon fab icon="google-plus-g" />
                  </a>
                </li>
                <li>
                  <a href="#!">
                    <MDBIcon fab icon="twitter" />
                  </a>
                </li>
              </ul>
            </li>
            <MDBInput
              type="text"
              default="Search"
              style={{
                color: "#fff",
                padding: "0 10px 8px 30px",
                boxSizing: "border-box"
              }}
            />
            <MDBSideNavNav>
              <MDBSideNavCat
                name="Maps"
                id="maps"
                icon="map"
              >
                <NavLink to="/maps">All Maps</NavLink>
                <NavLink to="/maps/featured">Featured Maps</NavLink>
                <NavLink to="/createmap">Add Map</NavLink>
              </MDBSideNavCat>
              <MDBSideNavCat
                iconRegular
                name="Creators"
                id="creators"
                icon="user"
              >
                <NavLink to="/creators">All Creators</NavLink>
                <MDBSideNavItem>Add Creator(not working)</MDBSideNavItem>
              </MDBSideNavCat>
              <MDBSideNavCat 
              name="Settings" 
              id="settings" 
              icon="cogs">
                <MDBSideNavItem>Setting1</MDBSideNavItem>
                <MDBSideNavItem>Setting2</MDBSideNavItem>
              </MDBSideNavCat>
            </MDBSideNavNav>
          </MDBSideNav>
          <main style={mainStyle}>
            <MDBContainer fluid style={{ height: 2000 }} className="mt-5">
                    {this.props.children}
            </MDBContainer>
          </main>
          </Fragment>
          </Router>
          
        </div>
      
    );
  }
}

export default DoubleNavigationPage;