import React from "react";
import {API, Auth} from "aws-amplify";
import {
    MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    ToastContainer,
    toast
} from "mdbreact";
const apiName = "ICApi";
const apiPath = "/maps/featured";
const apiPathFeaturedAdd = "admin/maps/featured/add";
const apiPathFeaturedRemove = "admin/maps/featured/del";
const apiPathFeaturedUpdate = "admin/maps/featured/update";
const apiPathTheBlock = "admin/maps/featured/theblock";

class AllMaps extends React.Component {
    constructor(propsN) {
        super(propsN);
        this.state = {
            isLoading: true

        }
    };

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }
        const token = await Auth.currentSession().then((s) => s.idToken.jwtToken);
        this.setState({token});
        this.getmaps();
        this.getTheBlock();
    }

    getmaps = async () => {
        try {
            const mapsRes = await API.get(apiName, apiPath);
            const maps = mapsRes.Items
            this.setState({maps});
        } catch (e) {
            alert(e);
        }

        this.setState({isLoading: false});
    }

    getTheBlock = async () => {
        try {
            const api_call = await fetch(`https://island-codes.com/media/featured/theBlock.json`);
            const theBlock = await api_call.json();
            this.setState({theBlock});
        } catch (e) {toast.error('Error getting block: ' + e, {position: "top-right"})}
    }

    addToFeatured = async(code) => {
        try {
            await API.post(apiName, apiPathFeaturedAdd, { body : {code: code}, headers: {Authorization: this.state.token}});
            this.getmaps();
            toast.success('Successfully added to featured.', {position: "top-right"})
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }
    removeFromFeatured = async(code) => {
        console.log(code)
        try {
            await API.post(apiName, apiPathFeaturedRemove, { body : {code: code}, headers: {Authorization: this.state.token}});
            this.getmaps();
            toast.success('Successfully removed map', {position: "top-right"})
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }
    updateFeatured = async() => {
        try {
            await API.post(apiName, apiPathFeaturedUpdate, {body: "", headers: {Authorization: this.state.token}})
            toast.success('Successfully uploaded featured maps.', {position: "top-right"})
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }

    updateTheBlock = async(code) => {
        console.log(code)
        try {
            await API.post(apiName, apiPathTheBlock, { body : {code: code}, headers: {Authorization: this.state.token}});
            toast.success('Successfully updated, change takes 1-2 minutes', {position: "top-right"});
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }

    renderMapsList(maps) {
        const tableCells = maps.map(map => {
            return (
                <tr key={map.code}>
                    <td>
                        <img
                            src={map.images && map.images.main
                            ? "https://cdn.eu.com/c/islandcodes/media/img/768/" + map.images.main + ".jpg"
                            : "https://cdn.eu.com/c/islandcodes/media/img/768/fortnite-creative-mode.jpg"}
                            height="50"
                            alt={map.name}></img>
                    </td>
                    <td>{map.name}</td>
                    <td>{map.creator}</td>
                    <td>{map.code}</td>
                    <td>{this.getFormatedTime(map.timestamp)}</td>
                    <td>
                        <span className="badge badge-danger" onClick={() => this.removeFromFeatured(map.code)}>
                            <i className="far fa-trash-alt"></i>
                        </span>
                    </td>
                </tr>
            );
        })
        return tableCells;
    }

    handleChangeFilter = event => {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    getFormatedTime(timestamp) {
        var a = new Date(timestamp * 1000);
        var months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    notLoggedIn() {
        this
            .props
            .history
            .push("/login")
    }

    renderMaps() {
        return (
            <div className="maps">
                <MDBRow>
                    <MDBCol className="text-center">
                        <h1>Featured Maps</h1>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="my-5">
                    <MDBCol md="7">
                        <MDBCard>
                            <MDBCardBody>
                                <MDBTable>
                                    <MDBTableHead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Cretor</th>
                                            <th>Code</th>
                                            <th>Posted</th>
                                            <th>Remove</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {!this.state.isLoading && this.renderMapsList(this.state.maps)}
                                    </MDBTableBody>
                                </MDBTable>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md="5">
                        <MDBCard className="text-center">
                            <MDBCardBody>
                                <h2>Add map to featured</h2>
                                <MDBInput label="Code" id="codeToAdd" value={this.state.codeToAdd} onChange={this.handleChangeFilter} />
                                <MDBBtn color="white yellow accent-2" onClick={() => {this.addToFeatured(this.state.codeToAdd)}}>Add</MDBBtn>
                            </MDBCardBody>

                        </MDBCard>
                        <MDBCard className="text-center my-5">
                            <MDBCardBody>
                                <h2>Update featured maps</h2>
                                <MDBBtn color="white yellow accent-2" onClick={this.updateFeatured}>Update</MDBBtn>
                            </MDBCardBody>

                        </MDBCard>
                        <MDBCard className="text-center mt-5">
                            <MDBCardBody>
                                <h2>The Block</h2>
                                <h4>Current Block:</h4>
        
                                <img src={this.state.theBlock && "https://cdn.eu.com/c/islandcodes/media/img/768/" + this.state.theBlock.images.main + ".jpg"} height="125" alt="TheBlock"></img>
                                <h4 className="text-uppercase">{this.state.theBlock && this.state.theBlock.name}</h4>
                                <h5 className="text-uppercase">{this.state.theBlock && this.state.theBlock.creator}</h5>
                                <MDBInput label="Code" id="codeTheBlock" value={this.state.codeTheBlock} onChange={this.handleChangeFilter} />
                                <MDBBtn color="white yellow accent-2" onClick={() => {this.updateTheBlock(this.state.codeTheBlock)}}>Update</MDBBtn>
                            </MDBCardBody>

                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>

        );
    }

    render() {
        return (
            <div className="Maps">
                {this.props.isAuthenticated
                    ? this.renderMaps()
                    : this.notLoggedIn()}
                <ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={3000}/>
            </div>
        );
    }
}

export default AllMaps;