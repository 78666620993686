export default {
    apiGateway: {
      REGION: "eu-central-1",
      URL: "https://api.island-codes.com"
    },
    cognito: {
      REGION: "eu-central-1",
      USER_POOL_ID: "eu-central-1_x4HcBDwSb",
      APP_CLIENT_ID: "55ij9cfo760amb84lt77qj1vrj",
      IDENTITY_POOL_ID: "eu-central-1:2b2c10c9-428e-45ca-b94c-f5df14d74ece"
    }
  };