import React, {Fragment} from "react";
import { Auth, API, Storage } from "aws-amplify";
import { 
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    ToastContainer,
    toast
     } from "mdbreact";


const apiName = "ICApi";
const apiPath = "maps";
const apiPathUpdate = "admin/maps/map/update";


class EditMap extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            code: this.props.location.pathname.substring(11) || 0,
            tempImages: {},
            updateImages: false
        }
    };

    getPickerValue = (value) => {
        //console.log(value);
      }

      async componentDidMount() {
        const options = { 
            'queryStringParameters': {
                'code': this.state.code
            }
        };
    
        try {
           
            const mapRes = await this.maps(options);
            const map = mapRes.Items[0];
           
            this.setState({ map });
    
            const allMapsOptions = {
              'queryStringParameters': {
                'creator': map.creator
              }
            }
            const mapsRes = await this.maps(allMapsOptions);
            const maps = mapsRes.Items
            this.setState({ maps });
    
        } catch (e) {
            console.log(e);
            toast.warning('Error fetching map, please try again', {
                position: "top-right",
              });
        }
    
        this.setState({ isLoading: false });
        }
    
      maps(options) {
        return API.get(apiName, apiPath, options);
      }
    
    
    

    onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
        const map = this.state.map;
        const imageId = event.target.id.substring(6);
        const fileName = event.target.files[0].name;
        const extension = fileName.substring(fileName.lastIndexOf('.')+1, fileName.length);
        const imageName = this.state.map.code + imageId + "." + extension;
        Storage.configure({ level: 'private' });
        Storage.put("map/" + imageName, event.target.files[0]);
        if(imageId === "main") {
            if (map.images) {
                map.images.main = this.state.map.code + imageId;
            } else {
                map.images = {main: this.state.map.code + imageId};
            }
            setTimeout(()=>{this.setState({tempImages: {main: imageName}})}, 1000);
        } else if (imageId) {
            if (map.images) {
                if (map.images.additional) {
                    map.images.additional.push(this.state.map.code + imageId);
                } else {
                    map.images.additional = [this.state.map.code + imageId];
                }
            } else {
                map.images = {additional: [this.state.map.code + imageId]};
            }
        }
        toast.success('Successfully updated image.', {
            position: "top-right",
          });
        this.setState({map});
        this.setState({updateImages: true})
    } else {
        toast.error('Problem uploading image.', {
            position: "top-right",
          });
    }
    }

    handleChangeData = e => {
        const map = this.state.map
        map[e.target.id] = e.target.value
        this.setState({ map })
      }

      handleChangeYouTubeID = e => {
        const map = this.state.map
        map[e.target.id] = e.target.value.replace("https://www.youtube.com/watch?v=", "").split("&")[0];
        this.setState({ map })
      }

    handleChangeGamemodes = e => {
        // current array of options
        const map = this.state.map
        let index;
        const id = e.target.id.substring(7);
        
        if (e.target.checked) {
                if (!map.gameModes) {
                    map.gameModes = [id];
                } else if (map.gameModes.length < 4){
                    map.gameModes.push(id)
                }
        } else {
          // or remove the value from the unchecked checkbox from the array
          index = map.gameModes.indexOf(id)
          map.gameModes.splice(index, 1)
        }
     
        // update the state with the new array of options
        this.setState({ map })
     
       }

    handleUpdateMap = async () => {
        let options = {
            body: this.state.map,
            'queryStringParameters': {
                'updateImages': this.state.updateImages
              }
        }

        try {
            const auth = await Auth.currentSession().then((s) => {
                const auth = {
                    username: s.idToken.payload["cognito:username"],
                    token: s.idToken.jwtToken
                }
                 return auth;
            });
            options.headers = {Authorization: auth.token};
            await this.postMapUpdate(options);
            toast.success('Successfully updated map.', {
                position: "top-right",
              });
            setTimeout(() => {
                this.props.history.push("/maps");
            }, 1000)
             
        } catch (e) {
            toast.error('Error updating map. Verify account or try to re-login.', {
                position: "top-right",
              });
        }
    }

    postMapUpdate(options) {
        return API.post(apiName, apiPathUpdate, options);
    }

    handleDeleteAddImage = (index) => {
        let map = this.state.map;
        map.images.additional.splice(index, 1);
        if (map.images.additional.length === 0) {
            delete map.images.additional;
        }
        let tempImages = this.state.tempImages;
        delete tempImages.main;
        this.setState({map});
    }

    handleDeleteMainImage = () => {
        let map = this.state.map;
        let tempImages = this.state.tempImages;
        delete tempImages.main;
        delete map.images.main;
        this.setState({map, tempImages});
    }

    renderLander() {
        return (
            <Fragment>
            <MDBRow>
            <MDBCol className="headingBlue text-center firstBlock">
            <h1 className="display-1 text-uppercase white-text featuredHeading">Loading</h1>
            </MDBCol>
            </MDBRow>
            <MDBRow>
            <MDBCol className="searchBlock text-center text-white">
            <div className="spinner-grow my-5" role="status">
            <span className="sr-only">Loading Maps...</span>
            </div>
            <h1>Loading Maps</h1><h5>If this doesn't refresh try to log in again.</h5>
            </MDBCol>
            </MDBRow>
        </Fragment>
        );
    }

    
    renderPage(m, tempImages) {
        return (
        
            <Fragment>
                <MDBRow>
                <MDBCol>
                <MDBCard>
                                <MDBCardBody>
                                    <MDBRow>
                                        <MDBCol size="12" md="5" className="text-center">
                                            <h3>Images:</h3>
                                            <h4 className="mt-3">Main:</h4>
                                            <img src={
                                                tempImages.main ?
                                                "https://s3.eu-central-1.amazonaws.com/upload.island-codes.com/public/map/" + tempImages.main
                                                :
                                                m.images && m.images.main ? "https://cdn.eu.com/c/islandcodes/media/img/640/" + m.images.main + ".jpg" : "https://cdn.eu.com/c/islandcodes/media/img/768/fortnite-creative-mode.jpg"} alt="Image stil uploading!" className="img-thumbnail mx-auto" />
                                            
                                            <MDBRow>
                                                <MDBCol size="10">
                                                <form>
                                                    <div className="file-field md-form">
                                                        <div className="btn btn-success btn-sm float-left poligonButton1"><span style={{fontSize:".8rem"}}>Choose file</span>
                                                        <input type="file" id="image-main" onChange={this.onImageChange} />
                                                        </div><div className="file-path-wrapper"><input className="file-path validate" type="text" disabled placeholder={"File: " + (m.images ? m.images.main : "Not set: Main Image")} />
                                                        </div> 
                                                    </div>
                                                </form>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                    <div className="mt-4 float-left" onClick={this.handleDeleteMainImage}><span className="badge danger badge-danger"><i className="far fa-trash-alt"></i></span></div>
                                                </MDBCol>
                                            </MDBRow>
                                            
                                            
                                            <h4 className="mt-3">Additional:</h4>
                                            <MDBRow>
                                                <MDBCol size="10">
                                                <form>
                                                    <div className="file-field md-form">
                                                        <div className="btn btn-success btn-sm float-left poligonButton1"><span style={{fontSize:".8rem"}}>Choose file</span>
                                                        <input type="file" id="image-additional1" onChange={this.onImageChange} />
                                                        </div><div className="file-path-wrapper"><input className="file-path validate" type="text" disabled placeholder={(m.images && m.images.additional && m.images.additional[0]  ? m.images.additional[0] : "Not set: Additional 1")} />
                                                        </div> 
                                                    </div>
                                                </form>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                {m.images && m.images.additional && m.images.additional[0] && 
                                                    <div className="mt-4 float-left" onClick={() => {this.handleDeleteAddImage(0)}}><span className="badge danger badge-danger"><i className="far fa-trash-alt"></i></span></div>
                                                }
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol size="10">
                                                <form>
                                                    <div className="file-field md-form">
                                                        <div className="btn btn-success btn-sm float-left poligonButton1"><span style={{fontSize:".8rem"}}>Choose file</span>
                                                        <input type="file" id="image-additional2" onChange={this.onImageChange} />
                                                        </div><div className="file-path-wrapper"><input className="file-path validate" type="text" disabled placeholder={(m.images && m.images.additional && m.images.additional[1] ? m.images.additional[1] : "Not set: Additional 2")} />
                                                        </div> 
                                                    </div>
                                                </form>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                {m.images && m.images.additional && m.images.additional[1] && 
                                                    <div className="mt-4 float-left" onClick={() => {this.handleDeleteAddImage(1)}}><span className="badge danger badge-danger"><i className="far fa-trash-alt"></i></span></div>
                                                }
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol size="10">
                                                <form>
                                                    <div className="file-field md-form">
                                                        <div className="btn btn-success btn-sm float-left poligonButton1"><span style={{fontSize:".8rem"}}>Choose file</span>
                                                        <input type="file" id="image-additional3" onChange={this.onImageChange} />
                                                        </div><div className="file-path-wrapper"><input className="file-path validate" type="text" disabled placeholder={(m.images && m.images.additional && m.images.additional[2] ? m.images.additional[2] : "Not set: Additional 3")} />
                                                        </div> 
                                                    </div>
                                                </form>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                {m.images && m.images.additional && m.images.additional[2] && 
                                                    <div className="mt-4 float-left" onClick={() => {this.handleDeleteAddImage(2)}}><span className="badge danger badge-danger"><i className="far fa-trash-alt"></i></span></div>
                                                }
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol size="10">
                                                <form>
                                                    <div className="file-field md-form">
                                                        <div className="btn btn-success btn-sm float-left poligonButton1"><span style={{fontSize:".8rem"}}>Choose file</span>
                                                        <input type="file" id="image-additional4" onChange={this.onImageChange} />
                                                        </div><div className="file-path-wrapper"><input className="file-path validate" type="text" disabled placeholder={(m.images && m.images.additional && m.images.additional[3] ? m.images.additional[3] : "Not set: Additional 4")} />
                                                        </div> 
                                                    </div>
                                                </form>
                                                </MDBCol>
                                                <MDBCol size="1">
                                                {m.images && m.images.additional && m.images.additional[3] && 
                                                    <div className="mt-4 float-left" onClick={() => {this.handleDeleteAddImage(3)}}><span className="badge danger badge-danger"><i className="far fa-trash-alt"></i></span></div>
                                                }
                                                </MDBCol>
                                            </MDBRow>
                                            
                                            <h5>Don't forget to click SAVE CHANGES so save images.</h5>
                                        </MDBCol>
                                        <MDBCol size="12" md="7">
                                            <MDBInput label="Name (This will not change the in game name of the island!" className="mt-5" id="name" value={m.name} onChange={this.handleChangeData} maxLength="100" />
                                            <MDBInput type="textarea" label="Description" id="description" rows="3" value={m.description} onChange={this.handleChangeData}  maxLength="205" />
                                            <MDBInput label="YouTube ID ( https://www.youtube.com/watch?v=[ID])" hint="ex: zzcFaCQejz8" id="youtubeID" value={m.youtubeID} onChange={this.handleChangeYouTubeID} />
                                            <div className="text-left"><h3>Game Type:</h3></div>
                                            <div className="w-100 my-4 float-left">
                                            <MDBRow>
                                                <MDBCol size="6" sm="6" md="3" className="px-0">
                                                <MDBInput label="MINI GAMES" type="checkbox" id="search-Minigames" checked={m.gameModes && m.gameModes.indexOf("Minigames") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="TEAM DEATHMATCH" type="checkbox" id="search-TeamDeathmatch" checked={m.gameModes && m.gameModes.indexOf("TeamDeathmatch") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="DEATHRUN" type="checkbox" id="search-Deathrun" checked={m.gameModes && m.gameModes.indexOf("Deathrun") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                
                                                <MDBInput label="EPIC FEATURED" type="checkbox" id="search-EpicFeatured" checked={m.gameModes && m.gameModes.indexOf("EpicFeatured") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="FREE FOR ALL" type="checkbox" id="search-FreeForAll" checked={m.gameModes && m.gameModes.indexOf("FreeForAll") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                </MDBCol>
                                                <MDBCol size="6" sm="6" md="3" className="px-0">
                                                <MDBInput label="RACES" type="checkbox" id="search-Races" checked={m.gameModes && m.gameModes.indexOf("Races") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="FUN MAPS" type="checkbox" id="search-FunMaps" checked={m.gameModes && m.gameModes.indexOf("FunMaps") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="ESCAPE" type="checkbox" id="search-Escape" checked={m.gameModes && m.gameModes.indexOf("Escape") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="PUZZLE" type="checkbox" id="search-Puzzle" checked={m.gameModes && m.gameModes.indexOf("Puzzle") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="COINS" type="checkbox" id="search-Coins" checked={m.gameModes && m.gameModes.indexOf("Coins") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                </MDBCol>
                                                <MDBCol size="6" sm="6" md="3" className="px-0">
                                                <MDBInput label="HIDE AND SEEK" type="checkbox" id="search-HideAndSeek" checked={m.gameModes && m.gameModes.indexOf("HideAndSeek") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="PARKOUR" type="checkbox" id="search-Parkour" checked={m.gameModes && m.gameModes.indexOf("Parkour") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="MAZES" type="checkbox" id="search-Mazes" checked={m.gameModes && m.gameModes.indexOf("Mazes") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="REMAKES" type="checkbox" id="search-Remakes" checked={m.gameModes && m.gameModes.indexOf("Remakes") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="EVENT SUBMISSIONS" type="checkbox" id="search-EventSubmissions" checked={m.gameModes && m.gameModes.indexOf("EventSubmissions") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                </MDBCol>
                                                <MDBCol size="6" sm="6" md="3" className="px-0">
                                                <MDBInput label="THE BLOCK" type="checkbox" id="search-TheBlock" checked={m.gameModes && m.gameModes.indexOf("TheBlock") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="ADVENTURE" type="checkbox" id="search-Adventure" checked={m.gameModes && m.gameModes.indexOf("Adventure") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="EDIT COURSES" type="checkbox" id="search-EditCourses" checked={m.gameModes && m.gameModes.indexOf("EditCourses") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="AIM COURSES" type="checkbox" id="search-AimCurses" checked={m.gameModes && m.gameModes.indexOf("AimCurses") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                <MDBInput label="CHALLENGES (PRIZE POOL)" type="checkbox" id="search-ChallengesPrizepool" checked={m.gameModes && m.gameModes.indexOf("ChallengesPrizepool") >= 0 ? true : false} onChange={this.handleChangeGamemodes} />
                                                </MDBCol>
                                            </MDBRow>
                                               
                                                <h5>Selected order: {m.gameModes && <span style={{fontSize: "1.7rem"}}>{m.gameModes[0]}</span>} {m.gameModes && m.gameModes[1] && <span>, {m.gameModes[1]}</span>}{m.gameModes && m.gameModes[2] && <span>, {m.gameModes[2]}</span>}</h5>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow>
                                        <MDBCol>
                                            <MDBRow className="mt-4">
                                                <MDBCol size="0" md="3">

                                                </MDBCol>
                                                <MDBCol size="12" md="6" className="text-center float-right" onClick={this.handleUpdateMap}>
                                                <MDBBtn color="white yellow accent-2" onClick={this.uploadImage}>Save Changes</MDBBtn>
                                                </MDBCol>
                                               
                                            </MDBRow>
                                        </MDBCol>
                                    </MDBRow>
                                
                                </MDBCardBody>
                            
                            </MDBCard>
                </MDBCol>
                </MDBRow>
            </Fragment>
        
        );
    }

    render() {
    return (
      <div className="CreateMap">
        {this.props.isAuthenticated && !this.state.isLoading ? this.renderPage(this.state.map, this.state.tempImages) : this.renderLander()}
        <ToastContainer
                hideProgressBar={true}
                newestOnTop={true}
                autoClose={3000}
                />
      </div>
    );
  }
}

export default EditMap;