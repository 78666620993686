import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Dashboard from "./pages/Dashboard";
import AllMaps from "./pages/AllMaps";
import FeaturedMaps from "./pages/FeaturedMaps";
import CreateMap from "./pages/CreateMap";
import EditMap from "./pages/EditMap";
import Login from "./pages/Login";
import AllCreators from "./pages/AllCreators";



export default ({ childProps }) => {


  if (childProps.userGroup === "admin") {
    return (
  <Switch>
      <AppliedRoute path="/" exact component={Dashboard} props={childProps} />
      <AppliedRoute path="/maps" exact component={AllMaps} props={childProps} />
      <AppliedRoute path="/maps/featured" exact component={FeaturedMaps} props={childProps} />
      <AppliedRoute path="/maps/edit" component={EditMap} props={childProps} />
      <AppliedRoute path="/createmap" exact component={CreateMap} props={childProps} />
      <AppliedRoute path="/creators" exact component={AllCreators} props={childProps} />
      <AppliedRoute path="/login" exact component={Login} props={childProps} />
    
      { /* Finally, catch all unmatched routes */ }
      <Route render={function() {
              return <h1>Not Found</h1>;
            }} />
    </Switch>
    )
    
  } else {
    return <AppliedRoute path="/" component={Login} props={childProps} />
  }
 
}
