import React from "react";
import { API, Auth, Storage } from "aws-amplify";
import { 
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    ToastContainer,
    toast
     } from "mdbreact";
const apiName = "ICApi";
const apiPath = "maps/import/withcode";


class CreateMap extends React.Component {
    constructor(propsN) {
        super(propsN);
        this.state = {
            isLoading: true,
            batchMaps: ""

        }
    };

    componentDidMount = async () => {
        const token = await Auth.currentSession().then((s) => s.idToken.jwtToken);
        this.setState({token});
    }

    getPickerValue = (value) => {
        console.log(value);
      }

    handleChangeFilter = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    handleUpdateBatchMaps = (e) => {
        this.setState({
            batchMaps: e.target.value
          });
    }

    uploadMaps = async () => {
        const options = {
            body: this.state.batchMaps.replace(" ", "").split("\n"),
            headers: {Authorization: this.state.token}
        }
        try {
            await API.post(apiName, apiPath, options);
            toast.success('Successfully uploaded maps.', {
                position: "top-right",
              });
        } catch {toast.error('Problem uploading maps.', {
            position: "top-right",
          });}
        
    }

    uploadMap = async () => {
        const options = {
            body: this.state.code.replace(" ", ""),
            headers: {Authorization: this.state.token}
        }
        await API.post(apiName, apiPath, options);
        this.props.history.push("/maps/edit/" + this.state.code);
    }

    
    renderPage() {
        return (
                <MDBRow>
                    <MDBCol size="12" sm="6" md="6">
                        <MDBCard>
                            <MDBCardBody>
                                <h3 className="float-left">Batch load maps with codes:</h3>
                                <div className="form-group">
                                    <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="5"
                                    placeholder="One Code per Line"
                                    value={this.state.batchMaps}
                                    onChange={this.handleUpdateBatchMaps}
                                    />
                                </div>
                                <MDBBtn color="white yellow accent-2" onClick={this.uploadMaps}>Load in DB</MDBBtn>
                                
                            </MDBCardBody>
                           
                        </MDBCard>
                    </MDBCol>
                    <MDBCol size="12" sm="6" md="6">
                    <MDBCard className="text-center h-100">
                        <MDBCardBody>
                        <h3>Add single map</h3>
                        <MDBInput label="Code" id="code" value={this.state.code} onChange={this.handleChangeFilter} />
                        <MDBRow>
                            <MDBCol>
                            <MDBBtn color="white yellow accent-2" onClick={this.uploadMap}>Load and edit</MDBBtn>
                            </MDBCol>
                        </MDBRow>
                        </MDBCardBody>
                    </MDBCard>
                        
                    </MDBCol>
                </MDBRow>

        
        );
    }

    render() {
    return (
      <div className="CreateMap">
        {this.renderPage()}
        <ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={3000}/>
      </div>
    );
  }
}

export default CreateMap;