import React from "react";
import { API } from "aws-amplify";
import { MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn
     } from "mdbreact";
const apiName = "ICApi";
const apiPath = "/maps";


class AllMaps extends React.Component {
    constructor(propsN) {
        super(propsN);
        this.state = {
            isLoading: true,
            creator: "",
            code: "",
            gamemode: "",
            postdate: "",
            hasMedia: "",
            perPage: 10,
            lastItem: ""

        }
    };

    async componentDidMount() {
    if (!this.props.isAuthenticated) {
        return;
    }

    const options = { 
        'queryStringParameters': {
            'perPage': this.state.perPage
        }
    };

    try {
        const mapsRes = await this.maps(options);
        const maps = mapsRes.Items
        this.setState({ maps });
        this.setState({
            lastItem: {
            code: mapsRes.LastEvaluatedKey.code,
            creator: mapsRes.LastEvaluatedKey.creator
            }
          });
    } catch (e) {
        alert(e);
    }

    this.setState({ isLoading: false });
    }

    handlesearch = async () => {

        const options = { 
            'queryStringParameters': {
                'creator': this.state.creator,
                'code': this.state.code,
                'gamemode': this.state.gamemode,
                'postdate': this.state.postdate,
                'hasMedia': this.state.hasMedia,
                'perPage': this.state.perPage
            }
        };


        if (!this.props.isAuthenticated) {
            return;
        }
    
        try {
            const mapsRes = await this.maps(options);
            const maps = mapsRes.Items
            this.setState({ maps });
            this.setState({
                lastItem: {
                code: mapsRes.LastEvaluatedKey.code,
                creator: mapsRes.LastEvaluatedKey.creator
                }
              });
        } catch (e) {
            alert(e);
        }
    }


    maps(options) {

        return API.get(apiName, apiPath, options);
    }

    renderMapsList(maps) {
        const tableCells = maps.map(map => {
            return (
                <tr>
                    <td><img src={map.images && map.images.main? "https://cdn.eu.com/c/islandcodes/media/img/768/" + map.images.main + ".jpg" : "https://cdn.eu.com/c/islandcodes/media/img/768/fortnite-creative-mode.jpg"} height="50" alt={map.name}></img></td>
                    <td>{map.name}</td>
                    <td>{map.creator}</td>
                    <td>{map.code}</td>
                    <td>{this.getFormatedTime(map.timestamp)}</td>
                    <td>{map.gameModes}</td>
                    <td>{map.hasMedia}</td>
                </tr>
            );
        })
    return tableCells;
    }

    handleChangeFilter = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    getFormatedTime(timestamp) {
        var a = new Date(timestamp*1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
    }

    notLoggedIn() {
        this.props.history.push("/login")
    }

    
    renderMaps() {
        return (

            <div className="maps">
                <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <h3 className="float-left">Filters:</h3>
                                <MDBRow className="mt-3">
                                    <MDBCol>
                                        <MDBInput label="Creator" id="creator" value={this.state.creator} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBInput label="Code" id="code" value={this.state.code} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBInput label="Game Mode" id="gamemode" value={this.state.gamemode} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBInput label="Posted Date" id="postdate" value={this.state.postdate} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                    <MDBCol>
                                    <MDBInput label="Has Media" type="checkbox" id="hasMedia" value={this.state.hasMedia} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                    <MDBCol>
                                        <MDBBtn onClick={this.handlesearch} color="grey">Search</MDBBtn>
                                        <MDBInput label="Items per Page" type="tel" id="perPage" value={this.state.perPage} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                </MDBRow>
                                
                            </MDBCardBody>
                           
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <MDBRow className="my-5">
                    <MDBCol>
                        <MDBCard>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr><th>Image</th><th>Name</th><th>Cretor</th><th>Code</th><th>Posted</th><th>Gamemodes</th><th>Media</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                {!this.state.isLoading && this.renderMapsList(this.state.maps)}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
        </div>

        
        );
    }

    render() {
    return (
      <div className="Maps">
        {this.props.isAuthenticated ? this.renderMaps() : this.notLoggedIn()}
      </div>
    );
  }
}

export default AllMaps;