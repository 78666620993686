import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { MDBContainer, MDBRow, MDBCol, MDBInput, MDBCard, MDBCardBody, MDBBtn } from 'mdbreact';
import {Link} from 'react-router-dom';

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      isLoading: false
    };
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    try {
      await Auth.signIn(this.state.email, this.state.password)
      .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            const newPW = prompt("New password required:");

              Auth.completeNewPassword(user, newPW)
                   .then(user => {
                    this.setState({isLoading: false});
                    this.props.userHasAuthenticated(true);
                    this.props.history.push("/maps");
                   });
        } else {
          this.setState({isLoading: false});
          this.props.userHasAuthenticated(true);
          this.props.history.push("/maps");
          window.location.reload();
        }
    });
     
    } catch (e) {
      alert(e.message);
    }
  }


  render() {

return (

    <div className="h-100 regBG">
        <MDBContainer>
              <MDBRow>
                  <MDBCol md="6" className="mx-auto">
                  <MDBCard className="register-form regCard">
                      <MDBCardBody className="p-3 pt-5 p-sm-5">
                      <form>
                      <h2 className="text-center mb-4 white-text">Login</h2>
                      
                      <form onSubmit={this.handleSubmit}>
                        <MDBInput label="Creator-Tag / Username / Email" icon="user" className="bg-white" id="email" value={this.state.email} onChange={this.handleChange} /> 
                        <MDBInput label="Password" icon="lock" type="password" className="bg-white" id="password" value={this.state.password} onChange={this.handleChange} />
                        <MDBRow className="white-text">
                          <MDBCol>
                            <Link to="/register" className="text-white"><p>Create Account</p></Link>
                          </MDBCol>
                          <MDBCol className="text-right">
                          <p>Forgot password?</p>
                          </MDBCol>
                        </MDBRow>
                        <div className="text-center">
                        <MDBBtn color="yellow" type="submit" disabled={!this.validateForm()}>
                        <span className="text-uppercase">{this.state.isLoading ? "Loggin in..." : "Login"}</span>
                        </MDBBtn>
                        
                        </div>
                      </form>
                      
                  </form>
                      </MDBCardBody>
                  </MDBCard>
                  </MDBCol>
              </MDBRow>
        </MDBContainer>
            </div>

    
  );

}
}