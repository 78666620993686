import 'react-app-polyfill/ie9';
import React, { Component, Fragment } from "react";
import { Auth } from "aws-amplify";
import Navigation from './components/Navigation';
import Routes from "./Routes";



class App extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      userGroup: "user",
      accessKey: ""
    };
  }
  
  async componentDidMount() {
    try {
      await Auth.currentSession()
      .then( session => {
        this.setState({userGroup: session.accessToken.payload['cognito:groups'][0]});
      });
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
  }

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      userGroup: this.state.userGroup,
      logout: this.handleLogout,
      accessKey: this.state.accessKey
    };

    return (
      !this.state.isAuthenticating &&
      <Fragment>
      <Navigation props={childProps}>
        <Routes childProps={childProps} />   
      </Navigation>
        
      </Fragment>
        
      
    );
  }
}

export default App;
