import React, { Fragment } from "react";
import {Link} from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { MDBTable,
    MDBTableBody,
    MDBTableHead,
    MDBCard,
    MDBCardBody,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBIcon, MDBSelect, MDBSelectInput, MDBSelectOptions, MDBSelectOption, MDBBadge,
    ToastContainer,
    toast
     } from "mdbreact";
const apiName = "ICApi";
const apiPath = "admin/creators";
const apiPathVerify = "admin/creators/verify";
const apiPathNotify = "admin/creators/notify";
const apiPathReject = "admin/creators/reject";


class AllCreators extends React.Component {
    constructor(propsN) {
        super(propsN);
        this.state = {
            isLoading: true,
            creator: "",
            code: "",
            gamemode: "",
            postdate: "",
            hasMedia: "",
            perPage: "",
            lastItem: ""

        }
    };

    async componentDidMount() {
    if (!this.props.isAuthenticated) {
        return;
    }
    const token = await Auth.currentSession().then((s) => s.idToken.jwtToken);
    this.setState({token});
    this.getCreators();
    
    }

    getCreators = async () => {
        const options = {headers : {Authorization: this.state.token}};
        try {
            const mapsRes = await API.get(apiName, apiPath, options);
            const maps = mapsRes.Items
            this.setState({ maps });
        } catch (e) {
            console.log(e);
        }
    
        this.setState({ isLoading: false });
    }


    verifyCreator = async(creator) => {
        try {
            await API.post(apiName, apiPathVerify, { body : {creator: creator}, headers: {Authorization: this.state.token}});
            toast.success('Successfully verified Creator', {position: "top-right"});
            this.getCreators();
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }

    notifyCreator = async(creator) => {
        try {
            await API.post(apiName, apiPathNotify, { body : {creator: creator}, headers: {Authorization: this.state.token}});
            toast.success('Notified Creator', {position: "top-right"});
            this.getCreators();
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }

    rejectCreator = async(creator) => {
        try {
            await API.post(apiName, apiPathReject, { body : {creator: creator}, headers: {Authorization: this.state.token}});
            toast.success('Rejected Creator', {position: "top-right"});
            this.getCreators();
        } catch (e) {
            toast.error('Error: ' + e, {position: "top-right"})
        }
    }

    renderMapsList(creators, filterName, isVerified) {
        if (filterName) {
            creators = creators.filter(creator => creator.username.includes(filterName.toLowerCase()));
        }
        if (isVerified) {
            if (isVerified === "true") {
                creators = creators.filter(creator => creator.isVerified === true);
            } else if (isVerified === "false") {
                creators = creators.filter(creator => !creator.isVerified && creator.verificationCode !== "Disabled, please contact support");
            } else if (isVerified === "disabled") {
                creators = creators.filter(creator => creator.verificationCode === "Disabled, please contact support");
            }
        }
        const tableCells = creators.map((creator, index) => {
            return (
                <tr className="text-center">
                <td>{index}</td>
                    <td><img src={creator.image? "https://cdn.eu.com/c/islandcodes/media/img/creators/300/" + creator.image + ".jpg" : "https://cdn.eu.com/c/islandcodes/media/img/sys/creator_default.jpg"} height="30" alt={creator.username}></img></td>
                    <td>{creator.username.toUpperCase()}</td>
                    <td>{creator.email}</td>
                    <td>{creator.description && creator.description.substring(0, 45) + "..."}</td>
                    <td>{creator.isVerified ? <span className="green-text">Verified</span> : <span className="red-text">Not verified</span>}</td>
                    <td>{creator.verificationCode !== "Already verified creator" && creator.verificationCode}</td>
                    <td>{creator.created && this.getFormatedTime(creator.created)}</td>
                    <td>
                        { !creator.isVerified ?
                            <Fragment>
                                <span className="badge badge-info ml-2" style={{cursor: "pointer"}} onClick={() => {this.notifyCreator(creator.username)}}><MDBIcon icon="bell" /> Notify {creator.notifiedVerify && <MDBBadge color="danger">{creator.notifiedVerify}</MDBBadge>}</span>
                                <span className="badge badge-success ml-2" style={{cursor: "pointer"}} onClick={() => {this.verifyCreator(creator.username)}}><MDBIcon icon="user-check" /> Verify</span>
                                <span className="badge badge-danger ml-2" style={{cursor: "pointer"}} onClick={() => {this.rejectCreator(creator.username)}}><MDBIcon icon="times" /> Reject</span>
                            </Fragment>
                            : 
                            <span className="badge badge-danger ml-2" style={{cursor: "pointer"}} onClick={() => {this.rejectCreator(creator.username)}}><MDBIcon icon="times" /> Ban</span>
                        }
                       
                    </td>
                </tr>
            );
        })
    return tableCells;
    }

    handleChangeFilter = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    getFormatedTime(timestamp) {
        var a = new Date(timestamp*1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min ;
        return time;
    }

    notLoggedIn() {
        this.props.history.push("/login")
    }

    
    renderMaps() {
        return (
            <div className="maps">
               
               <MDBRow>
                    <MDBCol>
                        <MDBCard>
                            <MDBCardBody>
                                <h3 className="float-left">Filters:</h3>
                                <MDBRow className="mt-3">
                                    <MDBCol>
                                        <MDBInput label="Username" id="username" value={this.state.username} onChange={this.handleChangeFilter} />
                                    </MDBCol>
                                    
                                    <MDBCol md="5" className="text-center">
                                        <MDBBtn color="black" onClick={() => this.setState({isVerified: ""})}>All</MDBBtn>
                                        <MDBBtn color="green" onClick={() => this.setState({isVerified: "true"})}>Verified</MDBBtn>
                                        <MDBBtn color="red" onClick={() => this.setState({isVerified: "false"})}>Not verified</MDBBtn>
                                        <MDBBtn color="grey" onClick={() => this.setState({isVerified: "disabled"})}>Disabled</MDBBtn>
                                    </MDBCol>
                                   
                                </MDBRow>
                                
                            </MDBCardBody>
                           
                        </MDBCard>
                    </MDBCol>
                </MDBRow>

                <MDBRow className="my-5">
                    <MDBCol>
                        <MDBCard>
                            <MDBTable>
                                <MDBTableHead>
                                    <tr className="text-center"><th>Nr</th><th>Image</th><th>Username</th><th>Email</th><th>Description</th><th>Verified</th><th>Verify Code</th><th>Signup Date</th><th>Action</th>
                                    </tr>
                                </MDBTableHead>
                                <MDBTableBody>
                                {!this.state.isLoading && this.renderMapsList(this.state.maps, this.state.username, this.state.isVerified)}
                                </MDBTableBody>
                            </MDBTable>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
        </div>
        
        );
    }

    render() {
    return (
      <div className="Maps">
        {this.props.isAuthenticated ? this.renderMaps() : this.notLoggedIn()}
        <ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={3000}/>
      </div>
    );
  }
}

export default AllCreators;